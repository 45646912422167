html,body{
    overflow-x:hidden;
}
body{
    font-family: 'NanumSquare', sans-serif;
    background-color:#ededed;
}
*{-ms-overflow-style: none;}
*::-webkit-scrollbar { width: 0 !important; height:0 !important; }

/* user ui */
.inline-block{
    display:inline-block;
    vertical-align: top;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
}

.uploaded-image {
    width:100px;
    border:solid 1px #ccc;
    padding:10px;
    border-radius:10px;
    margin: 5px 0;
    background-color:#fff;
    font-size:0;
}
.uploaded-image img{
    width:100%;
    height:auto;
}
.uploader-image{
    margin-top:10px;
}