/* UI */
.ui-primary{
    position:fixed;
    z-index: 10;
}
.ui-fix{
    position:fixed;
    left:0;
    right:0;
}
.ui-fix-absolute{
    position:absolute;
}
.ui-fix-top{
    top:0;
}
.ui-fix-activities, .ui-fix-tab, .ui-fix-subtab{
    left:0;
    right:0;
}
.ui-fix-activities{
    top:45px;
    bottom:51px;
}
.ui-fix-tab{
    top:40px;
    bottom:51px;
}
.ui-fix-subtab{
    top:75px;
    bottom:51px;
}
.fix{
    width:100%;
    height:100%;
    display:table;
}
.fix-inner{
    display:table-cell;
    vertical-align: middle;
    padding:50px;
}
.fix-inner.fix-inner-hor{
    padding:5px 50px;
}
.fix-inner.fix-inner-hor.fix-inner-30{
    padding:5px 30px;
}
.fill-inner{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
}
.ui-card{
    border-radius:10px;
    width: calc(100% - 70px);
    margin-left:35px;
    margin-right:35px;
    padding:16px 12.5px;
    box-sizing:border-box;
}
.ver-center{
    position:fixed;
    top:50%;
    transform:translateY(-50%);
}
.center{
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}
.abs-center{
    position: absolute;;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}
.cover{
    position:fixed;
    top:35px;
    bottom:40px;
    left:0;
    right:0;
}
.bg-default{
    background-color:#ededed;
}
.bg-white{
    background-color:#fff;
}
.bg-green{
    background-color:#5c7321;
}
.bg-green-dark{
    background-color:#445900;
}
.bg-black{
    background-color:#333;
}
.bg-red{
    background-color:#ff7357;
}
.bg-red-bright{
    background-color:#ff9985;
}
.bg-blue{
    background-color:#1976d2;
}
.bg-blue-bright{
    background-color:#94caff;
}
.ui-card.bg-white{
    border:solid 1px #ccc;
}
.text-green{
    color:#5c7321;
}
.text-white{
    color:#fff;
}
.scroll-hor{
    overflow-x:auto;
    overflow-y:hidden;
    -webkit-overflow-scrolling: touch;
}
.scroll-ver{
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling: touch;
}
.scroll-both{
    overflow-x:auto;
    overflow-y:auto;
    -webkit-overflow-scrolling: touch;
}
button{
    cursor:pointer;
}




/* layout */
.float-container:after{
    content:"";
    display:block;
    clear:both;
}
.float-left{
    float:left;
}
.float-right{
    float:right;
}
.content-body{
    margin-top:40px;
}
.confirm-container{
    margin-top:30px;
}
.item-container{
    margin-top:-15px;
}
.item{
    margin-top:15px;
}

.row{
    margin-left:-5px;
    margin-right:-5px;
}
.row:after{
    clear:both;
    content:'';
    display:block;
}
.row-10{
    height:10%;
}
.row-15{
    height:15%;
}
.row-20{
    height:20%;
}
.row-25{
    height:25%;
}
.row-30{
    height:30%;
}
.row-33{
    height:33.3%;
}
.row-40{
    height:40%;
}
.row-45{
    height:45%;
}
.row-50{
    height:50%;
}
.row-55{
    height:55%;
}
.row-60{
    height:60%;
}
.row-66{
    height:66.6%;
}
.row-70{
    height:70%;
}
.row-75{
    height:75%;
}
.row-80{
    height:80%;
}
.row-85{
    height:85%;
}
.row-90{
    height:90%;
}
.row-100{
    height:100%;
}


.row-div{
    margin-top:10px;
}
.col{
    float:left;
    box-sizing:border-box;
    padding-left:5px;
    padding-right:5px;
}
.col-20{
    width:20%;
}
.col-25{
    width:25%;
}
.col-33{
    width:33.3%;
}
.col-50{
    width:50%;
}
.col-66{
    width:66.6%;
}
.col-75{
    width:75%;
}
.col-100{
    width:100%;
}

/* tab */
.tab, .subtab{
    left:0;
    right:0;
}
.scroll-inner{
    display:inline-block;
    box-sizing:border-box;
    white-space: nowrap;
}
.tab button, .subtab button{
    border-radius:10px;
    box-sizing:border-box;
    line-height:1em;
}

.tab{
    top:0;
    height:41px;
    border-bottom:solid 1px #ccc;
    box-sizing:border-box;
}
.tab .scroll-inner{
    padding:2.5px 5px;
    height:40px;
}


.tab button{
    display:inline-block;
    height:35px;
    font-size:15px;
    line-height:1em;
    padding:10px 15px;
    box-sizing:border-box;
    margin-left:15px;
    color:#515151;

    border-radius:10px;
}
.tab button.active{
    background-color:#5c7321;
    color:#fff;
}
.tab button:first-child{
    margin-left:0;
}


.subtab{
    top:40px;
    height:35px;
    background-color:#5c7321;
}
.subtab .scroll-inner{
    padding:5px 5px;
    height:35px;
}

.subtab button{
    display:inline-block;
    height:25px;
    font-size:15px;
    padding:5px 15px;
    color:#ededed;
}
.subtab button.active{
    background-color:#445900;
}

/* button */
.btn{
    display:block;
    width:100%;
    border-radius:10px;
    text-align:center;
    font-size:15px;
    line-height:1em;
    border:solid 1px;
    padding:9px;
    box-sizing:border-box;
}
.btn .description{
    margin-top:8px;
}
.btn .description>.text-inner{
    font-size:13px;
    line-height:16px;
    margin-top:-2px;
    margin-bottom:-2px;
}
button.deactive, .btn.deactive{
    pointer-events: none;
}
.text-green .btn, .btn-green{
    background-color:#5c7321;
    color:#fff;
    border-color:#5c7321;
}
.text-green .btn:active, .btn-green:active{
    background-color:#fff;
    color:#5c7321;
}
.text-green-dark .btn:active{
    background-color:#fff;
    color:#5c7321;
}
.btn.deactive{
    opacity:.5;
}

.bg-black .btn, .bg-red .btn, .bg-blue .btn, .bg-green-dark .btn{
    background-color:#ededed;
}
.bg-black .btn{
    color:#333;
}
.bg-red .btn{
    color:#ff7357;
}
.bg-blue .btn{
    color:#1976d2;
}
.bg-green-dark .btn{
    color:#445900;;
}

/* input */
.input-text{
    display:block;
    width:100%;
    font-size:15px;
    line-height:1em;
    border-radius:10px;
    padding:6.5px 14px;
    border:solid 1px #ccc;
    background-color:#fff;
    margin-top:8px;
    box-sizing:border-box;
}
.input-select{
    display:block;
    appearance: none;
    -webkit-appearance: none;
    font-size:15px;
    line-height:1em;

    text-align-last:center;
    text-align:center;

    width:100%;
    border-radius:10px;
    padding:6.5px 14px;
    border:solid 1px #ccc;
    background-color:#fff;
    margin-top:8px;
    box-sizing:border-box;
    background-position: right 10px center;
    background-size:11.5px 10px;
    background-repeat: no-repeat;
    background-image:url(../assets/icon/reverse-triangle.svg);
}
.input-checkbox{
    display:none;
}
.input-checkbox-container {
    display:block;
    width:100%;
    font-size:15px;
    line-height:1em;
    border-radius:10px;
    padding:9px 14px;
    color:#5c7321;
    border:solid 1px #5c7321;
    margin-top:8px;
    box-sizing:border-box;
}
.input-checkbox:checked + .input-checkbox-container{
    background-color:#5c7321;
    color:#fff;
}
.bg-black .input-checkbox + .input-checkbox-container, 
.bg-blue .input-checkbox + .input-checkbox-container, 
.bg-red .input-checkbox + .input-checkbox-container{
    border:solid 1px #fff;
    color:#fff;
}
.bg-black .input-checkbox:checked + .input-checkbox-container, 
.bg-blue .input-checkbox:checked + .input-checkbox-container, 
.bg-red .input-checkbox:checked + .input-checkbox-container{
    background-color:#fff;
}
.bg-black .input-checkbox:checked + .input-checkbox-container{
    color:#333;
}
.bg-blue .input-checkbox:checked + .input-checkbox-container{
    color:#1976d2;
}
.bg-red .input-checkbox:checked + .input-checkbox-container{
    color:#ff7357;
}
.input-checkbox-container:after{
    display:block;
    content:"";
    clear:both;
}

.input-checkbox-container .label{
    float:left;
    padding:0;
}
.input-checkbox-icon{
    float:right;
    width:15px;
    height:15px;
    border-radius:3px;
    box-sizing: border-box;
    border:solid 1px #5c7321;
}
.input-checkbox:checked + .input-checkbox-container .input-checkbox-icon{
    position: relative;
    border:solid 1px #fff;
    color:#fff;
}
.bg-black .input-checkbox-icon, .bg-blue .input-checkbox-icon, .bg-red .input-checkbox-icon{
    border:solid 1px #fff;
}
.bg-black .input-checkbox:checked + .input-checkbox-container .input-checkbox-icon{
    border:solid 1px #333;
    color:#333;
}
.bg-blue .input-checkbox:checked + .input-checkbox-container .input-checkbox-icon{
    border:solid 1px #1976d2;
    color:#1976d2;
}
.bg-red .input-checkbox:checked + .input-checkbox-container .input-checkbox-icon{
    border:solid 1px #ff7357;
    color:#ff7357;
}
.input-checkbox:checked + .input-checkbox-container .input-checkbox-icon:after {
    content: "";
    display: block;
    left: 6px;
    top: 2px;
    position: absolute;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    border-left: solid 2px;
    border-bottom: solid 2px;
    border-color:inherit;
    width: 15px;
    height: 7px;
}

.value{
    display:block;
    font-size:15px;
    line-height:1em;
    text-align:center;
    color:#333;
    width:100%;
    border-radius:10px;
    padding:6.5px 14px;
    border:solid 1px #ccc;
    background-color:#ccc;
    margin-top:8px;
    box-sizing:border-box;
}
.value.bg-black{
    background-color:#333;
    color:white;
}

/* typography */
h3{
    font-size:17.5px;
    line-height:1em;
    font-weight:bold;
    padding:0 3.75px;
}
.message{
    font-size:14px;
    line-height:18px;
}
.label{
    font-size:14px;
    line-height:1em;
    padding:0 3.75px;
}
.text-center{
    text-align:center;
}

/* Intro */
.hide{
    opacity:0;
}
#page-intro{
    position:fixed;
    z-index: 11;
    top:0;
    bottom:0;
    left:0;
    right:0;
    transition:opacity .5s;
    background-color:#88a80d;
    color:#fff;
    text-align:center;
}
#page-intro>.inner{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:auto;
    font-weight:900;
}
#page-intro>.inner img {
    width: 70px;
    transform: translateX(15%);
    height: auto;
}
.intro-text-small {
    font-size: 17px;
    font-weight: 700;
    margin-top: 8px;
}
.intro-text-large {
    font-size: 25px;
}
.intro-text-bottom {
    position: absolute;
    bottom: 20px;
    font-size: 15px;
    left: 50%;
    transform: translateX(-50%);
}
/* login */

@media screen and (min-width:768px){
    #page-login{
        margin-left: calc(50% - 200px);
        margin-right: calc(50% - 200px);
        width: 400px;
    }

    #status-box .board {
        left: 50% !important;
        right: auto !important;
        top: 50% !important;
        width: 400px;
        transform: translate(-50%,-50%);
    }
}
@media screen and (max-width:320px){
    .fix-inner{
        padding:50px 30px;
    }
}

/* Nav bar */

#nav-bar{
    bottom:0;
    left:0;
    right:0;
    height:51px;
    border-top:solid 1px #cccccc;
    font-size:0;
    text-align:center;
    padding-top:8px;
    box-sizing:border-box;
    background-color:#f4f4f4;
}
#nav-bar button{
    display:inline-block;
    width:28%;
    text-align:center;
    font-size:0;
}

#nav-bar button .icon-active, #nav-bar button.active .icon, button#btn-return:active .icon, button#btn-return .icon-deactive, button#btn-return.deactive .icon{
    display:none;
}
#nav-bar button.active .icon-active, #nav-bar button .icon,button#btn-return:active .icon-active, button#btn-return.deactive .icon-deactive{
    display:inline-block;
}
#nav-bar img{
    height:20px;
    width:auto;
}
#nav-bar p{
    font-size:10px;
    font-weight:normal;
    color:#5c7321;
}
#nav-bar button.active p{
    font-weight:bold;
}
#btn-return.deactive p{
    color:#ccc;
}



/* Status Bar */

#status-bar{
    top:0;
    left:0;
    right:0;
    height:45px;
    background-color:#5c7321;
}
#status-bar h1{
    position: absolute;
    top:10px;
    left:10px;

    height:23px;
}
#status-bar h1:before {
    content: "";
    display: block;
    position: absolute;
    left: -10px;
    top: -10px;
    bottom: -10px;
    right: -10px;
}
#status-bar #btn-status{
    position: absolute;
    top:12px;
    right:10px;
    height:21px;
}
#status-bar #btn-status.active{
    opacity:0.5;
}
#status-bar h1 img, #status-bar #btn-status img{
    height:100%;
    width:auto;
    background-color:#5c7321;
}
#status-bar .cashbalance{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width: 130px;
    height: 25px;
    padding:4px 9px;
    box-sizing:border-box;
    font-size:15px;
    font-weight:bold;
    background-color:#4c6100;
    border:solid 1px #445900;
    border-radius:5px;
}
#status-bar .cashbalance span{
    float:right;
}
#status-bar .cashbalance span:first-child{
    float:left;
}
#changed-cash {
    position: absolute;
    left: 100%;
    top: 2.5px;
    font-size: 12px;
    line-height: 20px;
    width: 40px;
    height: 20px;
    border-radius: 5px;
    color: #445900;
    text-align: center;
    margin-left: 5px;
}

.change-parameter {
    opacity:0;
}
.fade{
    animation: fadeOut 2s forwards;
}

@keyframes fadeOut{
    0%{opacity:0}
    7%{opacity:1}
    60%{opacity:1}
    100%{opacity:0}
}

/* Status Box */
#status-box{
    top:35px;
    bottom:41px;
    left:0;
    right:0;
    background-color:#445900;
}
#status-board{
    position:relative;
    width:calc(100% - 75px);
    float:left;
    height:100%;
}
#status-board .period{
    position:absolute;
    right:15px;
    top:25px;
    font-size:17.5px;
    font-weight:bold;
}
#status-board .board-label {
    left: 15px;
    top: 25px;
    position: absolute;
    font-weight: bold;
    font-size: 17.5px;
}
#status-board .profile{
    position:absolute;
    left:50%;
    top:25px;
    transform:translateX(-50%);
    font-size:12px;
    padding:5px;
    border-radius:5px;
    white-space: nowrap;
    background-color:#5c7321;
}
#status-box .board{
    position: absolute;
    left:17px;
    right:17px;
    top:75px;
}

#status-box .board img{
    width:100%;
    height:auto;
}

.board-value{
    position: absolute;
}
.board-value>.inner{}
.board-value-icon{
    position: relative;
    transform:translate(-50%,-50%);
    white-space: nowrap;
}
.board-value-icon img{
    width:100%;
    height:auto;
    margin-left:5px;
}
.board-value-icon img:first-child{
    margin-left:0;
}
.board-value-value{
    position: absolute;
    top:50%;
    transform:translateX(-50%);
    font-size:12px;
    font-weight:bold;
    color:white;
    white-space: nowrap;
    text-align:center;
}
#status-list{
    position: relative;
    float:left;
    width:75px;
    height:100%;
    box-sizing:border-box;
    padding:20px 0 0 7.5px;
}
#status-list .value-container{
    margin-top:20px;
}
#status-list .value-container:first-child{
    margin-top:0;
}
#status-list .label{
    padding:0;
    font-size:13px;
}
#status-list .status-value{
    font-size:20px;
    margin-top:10px;
}
#status-list:before{
    content:"";
    display:block;
    position: absolute;
    width:1px;
    top:15px;
    left:0;
    bottom:15px;
    background-color:#c9d696;
}

/* Activities Box */
#activities-box{
    padding-top:30px;
    padding-bottom:40px;
}
.page-inner{
    margin-left: -5px;
    margin-right:-5px;
}
.btn-container{
    padding-left:5px;
    padding-right:5px;
}


/* Turn Start */
#btn-start {
    height:auto;
    padding:50px 0;
}
#page-session-start .btn{
    height:auto;
    min-height:70px;
}
#btn-start img{
    width:auto;
    height:100px;
}
#btn-start p{
    font-size:25px;
    margin-top:25px;
}
#btn-start:active .icon, #btn-start .icon-active{
    display:none;
}
#btn-start .icon, #btn-start:active .icon-active{
    display:inline-block;
}




/* Rule B */
#page-ruleb .btn.deactive{
    background-color:#ccc;
    border-color:#ccc;
}

/* Replay */
#page-replay{
    margin:0 50px;
    width:calc(100% - 100px);
}
#page-replay .btn.deactive{
    background-color:#ccc;
    border-color:#ccc;
}

/* Draw Card */
#page-drawcard{
    width:100%;
}
.visual-card{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:60%;
    perspective: 1000px;
}

.card-inner{
    position: relative;
    width:100%;
    padding-top:139.5%;   
}
.visual-card img{
    width:100%;
    height:auto;
}
.card-inner>div{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    transform-origin:center center;
    transform-style: preserve-3d;
    backface-visibility: hidden;

    border-radius: 4.5vw;
    background-color:#fff;
    overflow:hidden;
    box-shadow:3px 3px 10px #00000055;
    transition:all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.card-back{
    transform:rotateY(180deg);
    backface-visibility:visible;
}
.visual-card.reverse .card-front{
    transform:rotateY(180deg);
}
.visual-card.reverse .card-back{
    transform:rotateY(360deg);
    z-index: 1;
}
.card-back>img{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    /* opacity:0; */
}
.card-back>img.active{
    opacity:1;
}
#card-logo {
    position: absolute;
    left: 50%;
    top: 40%;
    width: 40%;
    transform: translate(-50%,-50%);
    font-size: 0;
}
#card-logo img{
    width:100%;
    height:auto;
}



/* Card */
#page-Card .fix-inner{
    padding:50px 34px;
}
.card{
    position: relative;;
    padding:16px;
    border-radius:10px;
}
.card-buttons,.card-message{
    margin-top:40px;
}
.card-buttons .btn.deactive{
    opacity:.5;
}

.card-message>.text-inner{
    font-size:14px;
    line-height:18px;
    margin-top:-2px;
    margin-bottom:-2px;
    padding:0 3.75px;
}

/* Action */
#popup-response{

}
#response-container{
    text-align:center;
}
#response-price-container{
    display:inline-block;
}
#response-price{
    font-size:150px;
    font-weight:200;
    border-bottom:solid 1px #fff;
}
.response-price-sub{
    font-size:14px;
    margin-top:5px;
}
.response-price-sub:after{
    content:"";
    clear:both;
    display:block;
}
.response-price-sub span:first-child{
    float:left;
}
.response-price-sub span:last-child{
    float:right;
}
#competitive-price{
    font-size:30px;
    margin-top:15px;
}
/* Session End */


/* Settlement */
.flowtable{}

.flowtable.square{
    background-color:#ededed;
    border-radius:10px;
    overflow:hidden;
}
.flowtable>.inner{
    position: relative;
}
.flowtable.square>.inner:after{
    content:"";
    display:block;
    clear:both;
}
.flowtable-col{
    float:left;
}
.square .flowtable-col{
    height:100%;
}
.flowtable-row{
    position: relative;
    text-align:center;
    border-left:solid 1px #526629;
    border-top:solid 1px #526629;
    box-sizing:border-box;
}
.split .flowtable-col{
    height:100%;
    background-color:#ededed;
}
.flowtable-row-container{
    position: absolute;
    width:66.6%;
    overflow:hidden;
}
.flowtable-row-container:first-child{
    top:0;
    left:0;
    border-radius:10px 10px 0 10px;
}
.flowtable-row-container:last-child{
    bottom:0;
    right:0;
    border-radius:0 10px 10px 10px;
    border-top:solid 1px #526629;
    border-left:solid 1px #526629;
    box-sizing:border-box;
}
.flowtable-col:first-child .flowtable-row{
    border-left:none;
}
.flowtable-col .flowtable-row:first-child{
    border-top:none;
}
.flowtable .cell-title{
    position: absolute;
    padding:0 5px;
    width:100%;
    top:10px;
    left:0;
    font-size:14px;
    line-height:18px;
    margin-top:-2px;
    box-sizing:border-box;
}
.flowtable .cell-number{
    position: absolute;
    width:100%;
    top:50%;
    left:0;
    font-size:14px;
}
.flowtable .cell-number>span{
    display:inline-block;
    padding-bottom:3px;
    border-bottom:solid 1px;
    border-color:inherit;
    transform:translateY(-50%);
}
.flowtable .cell-value{
    position: absolute;
    width:100%;
    bottom:20px;
    left:0;
    font-size:16px;
}
.flowtable .flowtable-row.tight .cell-value{
    bottom:10px;
}
.flowtable .row{
    margin:0;
    position: relative;
}
.flowtable .col{
    padding:0;
    position:relative;
}
.flowtable-border-left{
    border-left:solid 1px #526629;
}
.flowtable-border-left-bold{
    border-left:solid 2px #526629;
}
.flowtable-value{
    position: absolute;
    width:100%;
    text-align:center;
}
.flowtable-value-title{
    font-size:12px;
}
.flowtable-value-number{
    font-size:12px;
}
.flowtable-value-value{
    font-size:14px;
    margin-top:3px;
}
.flowtable-average {
    margin-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
.mode-center{
    transform:translate(-50%,-50%);
}
.mode-box{
    width:auto;
    border:solid 1px #526629;
    border-radius:12px;
    padding:4px 8px;
    background-color:#ededed;
    white-space: nowrap;
    z-index: 1;
}
.mode-box *{
    display:inline-block;
    line-height:14px;
    margin-top:0;
    margin-left:10px;
}
.mode-box *:first-child{
    margin-left:0;
}
.mode-hor{
    width:100%;
    padding:0 10px;
    box-sizing:border-box;
}
.mode-hor:after{
    content:"";
    clear:both;
    display:block;
}
.mode-hor *{
    display:inline-block;
    line-height:14px;
    margin-top:0;
}
.mode-hor .flowtable-value-title, .mode-hor .flowtable-value-number{
    float:left;
}
.mode-hor .flowtable-value-value{
    float:right;
}
.mode-value .flowtable-value-value{
    margin-top:0;
}

/* 손익계산서 */
#income-statement>.inner{
    padding-top:130%;
}

/* 재무상태표 */
#financial-statement{
    width:calc(210vw - 120px);
}
#financial-statement>.inner{
    padding-top:60%;
}



/* 그래프 */
.grid-line{
    stroke:#c9d696;
    stroke-width:1px;
    stroke-dasharray: 1, 2;
}

.grid-line.grid-line-col{
    stroke:#000;
    stroke-dasharray: 4, 4;
}
.grid-line-five,.grid-line-solid{
    stroke-dasharray: none;
}
.grid-line-ten{
    stroke-dasharray: none;
    stroke-width:2px;
}
.value-line{
    stroke:#526629;
    stroke-width:2px;
    fill:transparent;
}
.value-line-back{
    stroke:#fff;
    stroke-width:5px;
    fill:transparent;
}
.value-text{
    font-size: 15px;
    font-weight: 700;
    fill:#5c7321;
    stroke:#fff;
    stroke-width:3px;
    paint-order: stroke;
    text-anchor:middle;
}

.value-point {
    stroke: #5c7321;
    stroke-width: 3px;
    fill: #fff;
}


.period-text {
    font-size: 15px;
    stroke:#fff;
    stroke-width:3px;
    paint-order: stroke;
    text-anchor:middle;
}
.unit-text{
    font-size: 15px;
    fill:#555;
    stroke:#fff;
    stroke-width:3px;
    paint-order: stroke;

    text-anchor:middle;
    alignment-baseline:central;
}
.area-name{
    font-size: 15px;
    fill:#5c7321;
    stroke:#fff;
    stroke-width:3px;
    paint-order: stroke;
    alignment-baseline:central;
}

.bar{
    fill:#5c7321;
}

.user-name{
    font-size: 15px;
    fill:#333;
    stroke:#fff;
    stroke-width:3px;
    paint-order: stroke;
    text-anchor:middle;
    alignment-baseline:central;
}

#graph-container{
    overflow-x:auto;
    overflow-y:hidden;
    font-size:0;
}


/* Table */
.table-container{
    white-space: nowrap;
    font-size:0;
}
.table-col{
    display:inline-block;
    min-height:100%;
}
.table{
    font-size: 15px;
    text-align:center;
    background-color:#5c7321;
    min-height:calc(100vh - 90px);
}
.table th, .table td{
    border:solid 1px #333;
    min-width:100px;
    white-space: nowrap;
    background:#ededed;
}
.table th, .table-label, .table span{
    padding:10px 15px;
}
.table-value{
    padding:10px 0;
}
.table td span{
    display:inline-block;
    min-width:50px;
}
.table .table-label, .table td.table-label div{
    background-color:#c4d191;
}
.table .table-period{
    color:#fff;
    background-color:#5c7321;
}
#tab-total .table th, #tab-total .table td{
    min-width:auto;
}
#tab-total .table th, #tab-total .table-label, #tab-total .table td{
    padding:0;
    white-space: normal;
    vertical-align: top;
}
#tab-total .table .table-label div{
    background-color:#c4d191;
}
#tab-total .table div{
    height:30px;
    padding:10px 15px;
    vertical-align:top;
    box-sizing:border-box;
}
#tab-total .table td div{
    background-color:#ccc;
    border-bottom:solid 1px #000;
}

#tab-total .table td div:last-child{
    background-color:transparent;
    border-bottom:none;
}