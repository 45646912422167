/* nav */
#view-admin {
    padding-bottom: 50px;
}

#view-admin h1 {
    height: 35px;
}

#nav-admin h1 img {
    height: 100%;
    width: auto;
}

#nav-admin {
    padding: 10px 15px;
    border-bottom: solid 1px #cbcbcc;
}

#nav-admin .tab {
    border-bottom: none;
    height: 35px;
}

#nav-admin .tab button {
    font-size: 15px;
    padding: 10px 15px;
    margin-left: 0;
    height: 35px;
}

#nav-admin #tab-team {
    margin-left: 45px;
}

#nav-admin #tab-tab {
    margin-left: 30px;
    padding-left: 30px;
    border-left: solid 1px #999;
}

/* popup */
.admin-popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 50px;
    border-radius: 10px;
    background-color: #f7f7f7;
    border: solid 1px #cbcbcc;
}

/* save list */
#save-list li {
    border-top: solid 1px;
    line-height: 34px;
    padding: 5px;
}

#save-list li:after {
    content: "";
    display: block;
    clear: both;
}

#save-list li:first-child {
    border-top: none;
}

#save-list li .btn {
    float: right;
    width: auto;
}


/* new game */


/* QR Code */
#qr {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
}

#qr.active {
    display: block;
}

#qr>.inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
}


/* User Card */
.usercard {
    float: left;
    width: 100%;

    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    box-sizing: border-box;
}

.usercard>.inner {
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
}

.usercard-header,
.usercard-body {
    padding: 15px;
}

.usercard-status {
    margin-top: -15px;
}

.usercard-status.row {
    margin-left: -15px;
    margin-right: -15px;
}

.usercard-status .col {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.usercard-email {
    margin-top: 15px;
    border-top: solid 1px;
    padding-top: 15px;
    margin-bottom: 15px;
}

.usercard .divider {
    margin: 20px 0 5px;
    float: left;
    width: 100%;
    border: 2px solid #ededed;
}

#tab-userlist>.inner {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -20px;
}

/* Rank Table */
#admin-view {
    margin-top: 90px;
    margin-left: 50px;
    margin-right: 50px;
}

#tab-rank-table table {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

#tab-rank-table tr:nth-child(odd) {
    background-color: #fff;
}

#tab-rank-table tr:nth-child(even) {
    background-color: #f7f7f7;
}

#tab-rank-table tr:first-child {
    background-color: #5c7321;
}

#tab-rank-table th,
#tab-rank-table td {
    text-align: center;
    font-size: 15px;
    padding: 15px;
}

#btn-save-pdf {
    float: right;
    margin-bottom: 20px;
    width: 100px;
}

#print-title {
    display: none;
    margin: 20px;
}

#print-blind {
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ededed;
}

#print-blind>.inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: #aaa;
    font-weight: bold;
}

/* Team Analysis Table */
#tab-team-analysis-table {
    border-radius: 10px;
    overflow: hidden;
}

#nav-team-analysis-table {
    border-bottom: solid 1px #cbcbcc;
}

#nav-team-analysis-table .tab {
    border-bottom: none;
    height: auto;
}

#nav-team-analysis-table .tab button {
    font-size: 15px;
    border-radius: 0;
    padding: 7.5px 10px;
    margin-left: 0;
    border-right: solid 1px #fff;
    color: #fff;
}

#nav-team-analysis-table .tab.float-right button {
    border-left: none;
    border-left: solid 1px #fff;
}

#nav-team-analysis-table .tab button.active {
    background-color: #fff;
    color: #5c7321;
}

#admin-analysis-table {
    white-space: nowrap;
}

#admin-analysis-table .row-container {
    border-left: solid 1px #445900;
}

#admin-analysis-table .row {
    margin: 0;
    height: 35px;
    border-bottom: solid 1px;
}

#admin-analysis-table .row:last-child {
    border-bottom: none;
}

#admin-analysis-table .col {
    padding: 10px 0;
    font-size: 15px;
    height: 35px;
    text-align: center;
    box-sizing: border-box;
}

#admin-analysis-table .col:nth-child(2) {
    border-left: solid 1px;
}

#admin-analysis-table .row {
    min-width: 180px;
}

/* Graph */
#admin-graph {
    position: relative;
    padding-left: 120px;
}

.source-label {
    position: absolute;
    top: 20px;
    left: 20px;
}

.mark-container {
    position: relative;
    margin-top: 15px;
    white-space: nowrap;
}

.mark-container:first-child {
    margin-top: 0;
}

.mark-container>* {
    display: inline-block;
}

.mark {
    border: solid 1px #5c7321;
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.mark-0 {
    background-color: #5c7321;
}

.bar-0 {
    fill: #5c7321;
}

.bar-0+.value-line {
    stroke: #5c7321;
}

.mark-1 {
    background-color: #c9d696
}

.bar-1 {
    fill: #c9d696
}

.bar-1+.value-line {
    stroke: #c9d696
}

.mark-2 {
    background-color: #888;
}

.bar-2 {
    fill: #888;
}

.bar-2+.value-line {
    stroke: #888;
}

.mark-text {
    font-size: 13px;
    color: #000;
    vertical-align: middle;
    margin-left: 10px;
}




@media screen and (min-width:768px) {
    .usercard {
        width: 50%;
    }
}


@media screen and (min-width:992px) {
    .usercard {
        width: 25%;
    }
}



@media screen and (min-width:1200px) {
    .usercard-status .col {
        width: 50%;
    }

}

@media screen and (min-width:1700px) {
    .usercard-status .col {
        width: 33.3%;
    }
}