/* 
   v1.0 | 20181213
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, main, section, summary,
time, mark, audio, video, input {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, main {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* TF Website Defulat */
html, body{
    word-break: keep-all;
    word-wrap: break-word;
}
*{-webkit-tap-highlight-color: rgba(0,0,0,0);}
*:focus{outline:none;}
a{
    color:inherit;
    text-decoration: none;
    cursor:pointer;
}
button{
	padding:0;
	margin:0;
	border:none;
	outline: none;
	background-color:transparent;
	cursor:pointer;
}
input{
	-webkit-appearance: none;
	box-shadow: none;
	outline:none;
}